<template>
  <div class="container mx-auto text-center px-4 mt-5 md:mt-10">
    <p class="max-w-2xl mx-auto">
      We can’t find the page you requested. It may have moved, expired, or you
      may have been given a bad link.
    </p>
    <button-control to="/" class="mt-10">home</button-control>
  </div>
</template>

<script>
import ButtonControl from '@/components/ButtonControl.vue';

export default {
  name: 'PageNotFoundView',
  components: {
    ButtonControl,
  },
};
</script>
