<template>
  <section class="container mx-auto">
    <div class="text-center xl:hidden">
      <h1
        class="
          font-FuturaNowForAcuraCondXBlk
          uppercase
          px-4
          mt-7
          mb-9
          tracking-wider
          md:tracking-normal
        "
      >
        <span class="block mb-6">Congratulations<br />Winners!</span>
        <span
          class="
            font-AvenirBook
            block
            text-base
            leading-tight
            tracking-tight
            md:text-lg md:leading-tight md:tracking-normal
            lg:text-2xl lg:leading-tight lg:tracking-wide
            normal-case
          "
          >National Finalists will compete for national honors<br />and cash
          prizes in Las Vegas in early June.</span
        >
      </h1>
      <img
        alt="illustration of welcome to las vegas sign"
        src="@/assets/img/vegas-bg-mobile.jpg"
      />
    </div>
    <div
      class="
        hidden
        bg-vegas-bg bg-right bg-cover
        xl:flex xl:items-center xl:h-[525px] xl:px-14
        2xl:h-[488px] 2xl:px-28
      "
    >
      <h1
        class="
          font-FuturaNowForAcuraCondXBlk
          text-white text-center
          uppercase
          xl:max-w-xl
          2xl:max-w-2xl
        "
      >
        <span class="block mb-12">Congratulations Winners!</span>
        <span class="font-AvenirBook normal-case block xl:text-2xl 2xl:text-3xl"
          >National Finalists will compete for national honors and cash prizes
          in Las Vegas in early June.</span
        >
      </h1>
    </div>
  </section>
</template>

<script>
export default {
  name: 'DescriptionSection',
};
</script>
