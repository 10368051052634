<template>
  <section
    class="
      container
      mx-auto
      bg-custom-gray
      md:bg-racing-stripes md:bg-no-repeat md:bg-right-top md:bg-[length:65%]
    "
  >
    <h2
      class="
        font-FuturaNowForAcuraCondXBlk
        uppercase
        text-center
        lg:text-left
        mb-11
      "
    >
      {{ mainSectionHeading }}
    </h2>
    <div class="mb-10">
      <div
        class="
          font-AvenirHeavy
          text-lg
          md:text-xl
          lg:text-2xl
          2xl:text-3xl
          uppercase
          text-center text-[#636466]
          lg:text-left lg:px-4
          mb-4
        "
      >
        Zone 1
      </div>
      <div
        class="grid lg:grid-cols-[30%_20%_50%] bg-white border-2 border-black"
      >
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Hyung “Andy” Rhee
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District B
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Findlay Acura
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Colin Sato
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District D
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Acura of Honolulu
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Michael Burris
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            lg:border-none
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District C
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Fisher Acura
        </div>
      </div>
    </div>

    <div class="mb-10">
      <div
        class="
          font-AvenirHeavy
          text-lg
          md:text-xl
          lg:text-2xl
          2xl:text-3xl
          uppercase
          text-center text-[#636466]
          lg:text-left lg:px-4
          mb-4
        "
      >
        Zone 2
      </div>
      <div
        class="grid lg:grid-cols-[30%_20%_50%] bg-white border-2 border-black"
      >
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Peter Mungenast
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District C
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          MUNGENAST ST. LOUIS ACURA
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Lance Dailey
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District A
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          MULLER’S WOODFIELD ACURA
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Ian M Dominguez
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            lg:border-none
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District E
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          GILLMAN ACURA
        </div>
      </div>
    </div>

    <div class="mb-10">
      <div
        class="
          font-AvenirHeavy
          text-lg
          md:text-xl
          lg:text-2xl
          2xl:text-3xl
          uppercase
          text-center text-[#636466]
          lg:text-left lg:px-4
          mb-4
        "
      >
        Zone 3
      </div>
      <div
        class="grid lg:grid-cols-[30%_20%_50%] bg-white border-2 border-black"
      >
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Meghan Pakenham
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District F
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          AUTOSPORT ACURA OF DENVILLE
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Ashlynne Ratner
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District D
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          NORTHEAST ACURA
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Julia Gould
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            lg:border-none
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District C
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          SUNNYSIDE ACURA
        </div>
      </div>
    </div>

    <div class="mb-10">
      <div
        class="
          font-AvenirHeavy
          text-lg
          md:text-xl
          lg:text-2xl
          2xl:text-3xl
          uppercase
          text-center text-[#636466]
          lg:text-left lg:px-4
          mb-4
        "
      >
        Zone 4
      </div>
      <div
        class="grid lg:grid-cols-[30%_20%_50%] bg-white border-2 border-black"
      >
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Alexander Walz
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District C
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          AUTONATION ACURA NORTH ORLANDO
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            border-b-2 border-white
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          Robert Durand
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District C
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-b-2 border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          PROCTOR ACURA
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            bg-performance-red
            text-white
            px-4
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          William Crosier
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            capitalize
            px-4
            border-b-2 border-black
            lg:border-none
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          District A
        </div>
        <div
          class="
            h-12
            flex
            items-center
            justify-center
            lg:justify-start
            uppercase
            px-4
            lg:border-l-2
            border-black
            font-AvenirHeavy
            text-base
            md:text-lg
            lg:text-xl
            2xl:text-2xl
          "
        >
          HENDRICK ACURA
        </div>
      </div>
    </div>
    <p>
      Zone winners will receive communication about their prizes from the
      contest PHQ.
    </p>
    <p>
      Email
      <a
        href="mailto:faq@acurapcpa.com?subject=Acura Type S Storytelling Competition"
        >faq@acurapcpa.com</a
      >
      with questions.
    </p>
  </section>
</template>

<script>
export default {
  name: 'ZoneWinnersSection',
  props: {
    mainSectionHeading: {
      type: String,
      required: true,
    },
  },
};
</script>
