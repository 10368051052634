<template>
  <intro-section class="xl:mb-[3.75rem]" />
  <description-section
    v-bind="home.descriptionSection"
    class="mb-5 md:mb-8 xl:mb-12"
  />
  <national-finalists-section
    v-bind="home.nationalFinalistsSection"
    class="px-4 lg:px-14 mb-5 md:mb-8 xl:mb-12"
  />
  <zone-winners-section
    v-bind="home.zoneWinnersSection"
    class="px-4 lg:px-14 pt-14 pb-10 mb-12"
  />
</template>

<script>
// @ is an alias to /src
import IntroSection from '@/components/HomeView/IntroSection.vue';
import DescriptionSection from '@/components/HomeView/DescriptionSection.vue';
import NationalFinalistsSection from '@/components/HomeView/NationalFinalistsSection.vue';
import ZoneWinnersSection from '@/components/HomeView/ZoneWinnersSection.vue';
import HomeData from '@/data/home';

export default {
  name: 'HomeView',
  components: {
    IntroSection,
    DescriptionSection,
    NationalFinalistsSection,
    ZoneWinnersSection,
  },
  data() {
    return {
      home: HomeData,
    };
  },
};
</script>
