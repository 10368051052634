<template>
  <header
    class="
      container
      mx-auto
      flex
      items-center
      justify-end
      h-[3.125rem]
      px-4
      xl:px-8
    "
  >
    <router-link to="/">
      <img
        alt="Acura logo"
        src="@/assets/img/acura-logo-vector.svg"
        class="w-[100px] md:w-[140px]"
      />
    </router-link>
  </header>
</template>

<script>
export default {
  name: 'SiteHeader',
};
</script>
