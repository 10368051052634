<template>
  <component
    :is="type"
    :to="to"
    :href="href"
    class="
      font-btn
      text-sm
      md:text-base
      lg:text-lg
      2xl:text-[22px]
      uppercase
      no-underline
      border-2 border-performance-red
      py-[0.875rem]
      px-6
      lg:px-8
      xl:px-14
      2xl:px-16
      relative
      group
      overflow-hidden
      bg-white
      inline-block
      hover:cursor-pointer
    "
  >
    <span
      class="
        absolute
        top-0
        left-0
        flex
        w-0
        h-full
        mb-0
        transition-all
        duration-200
        ease-in-out
        transform
        translate-y-0
        bg-performance-red
        group-hover:w-full
      "
    ></span>
    <span
      class="relative group-hover:text-white transition-colors duration-200"
    >
      <slot>Placehoder Text</slot>
    </span>
  </component>
</template>

<script>
export default {
  name: 'ButtonControl',

  computed: {
    type() {
      if (this.to) {
        return 'router-link';
      }
      if (this.href) {
        return 'a';
      }

      return 'button';
    },
  },

  props: {
    to: {
      required: false,
    },
    href: {
      required: false,
    },
  },
};
</script>
