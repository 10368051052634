<template>
  <section class="container mx-auto">
    <img alt="type s graphic" src="@/assets/img/intro-bg.jpg" />
  </section>
</template>

<script>
export default {
  name: 'IntroSection',
};
</script>
