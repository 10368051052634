<template>
  <div class="flex flex-col border-2 border-black">
    <div
      v-html="finalistName"
      class="
        bg-performance-red
        text-white text-center
        font-AvenirHeavy
        text-lg
        md:text-xl
        lg:text-2xl
        2xl:text-3xl
        leading-snug
        pt-3
        pb-2
      "
    ></div>
    <div
      class="
        text-center
        font-AvenirHeavy
        text-base
        md:text-lg
        lg:text-xl
        2xl:text-2xl
        py-3
      "
      v-html="finalistDealershipName"
    ></div>
  </div>
</template>

<script>
export default {
  name: 'NationalFinalistBox',
  props: {
    finalistName: {
      type: String,
      required: true,
    },
    finalistDealershipName: {
      type: String,
      required: true,
    },
  },
};
</script>
