<template>
  <section class="container mx-auto">
    <h2
      class="
        font-FuturaNowForAcuraCondXBlk
        uppercase
        text-center
        lg:text-left
        mb-11
      "
    >
      {{ mainSectionHeading }}
    </h2>
    <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-8">
      <national-finalist-box
        v-for="(nationalFinalistBox, index) in nationalFinalistsBoxes"
        :key="index"
        v-bind="nationalFinalistBox"
      />
    </div>
  </section>
</template>

<script>
import NationalFinalistBox from '@/components/NationalFinalistBox.vue';

export default {
  name: 'NationalFinalistsSection',
  components: {
    NationalFinalistBox,
  },
  props: {
    mainSectionHeading: {
      type: String,
      required: true,
    },
    nationalFinalistsBoxes: {
      type: Array,
      required: true,
    },
  },
};
</script>
