import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import SiteHeader from './components/SiteHeader.vue';
import './index.scss';

createApp(App)
  .use(store)
  .use(router)
  .component('SiteHeader', SiteHeader)
  .mount('#app');
