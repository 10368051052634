const home = {
  nationalFinalistsSection: {
    mainSectionHeading: 'National Finalists',
    nationalFinalistsBoxes: [
      {
        finalistName: 'Hyung<br>“Andy” Rhee',
        finalistDealershipName: 'FINDLAY ACURA',
      },
      {
        finalistName: 'Colin<br>Sato',
        finalistDealershipName: 'ACURA OF<br>HONOLULU',
      },
      {
        finalistName: 'Peter<br>Mungenast',
        finalistDealershipName: 'MUNGENAST<br>ST. LOUIS ACURA',
      },
      {
        finalistName: 'Meghan<br>Pakenham',
        finalistDealershipName: 'AUTOSPORT ACURA<br>OF DENVILLE',
      },
    ],
  },
  zoneWinnersSection: {
    mainSectionHeading: 'Zone Winners',
  },
};

export default home;
